export default {
  BACK: `丞宂吾汕`,
  VAR_GREY: `var(--grey-700)`,
  PX_SOLID_RGBA: `1px solid rgba(255, 255, 255, 0.3)`,
  JACK: `已灰另弓`,
  LUCY: `申至示收`,
  DISABLED: `刎刀天吱弔叵丘巳`,
  YIMINGHE: `兵君止公失舌氏仍`,
  NOTHING_TO_SEE_HERE: `Nothing to see here!`,
  RETURN_HOME: `Return home`,
  IN_RESPONSE_TO_AN_OUTBREAK_OF: `ردًا على اندلاع النزاع المسلح بين قوات الدعم السريع السودانية والقوات المسلحة السودانية، وفي أوائل أبريل من عام 2023 بدأت منظمة C4ADS  في جمع ومراقبة وتحليل التوثيق المرئي للعنف المتزايد في جميع أنحاء السودان.`,
  C_ADS_IS_A_C_NONPROFIT_O: `إن منظمة C4ADS هي منظمة غير ربحية تعمل بموجب المادة 501 –ج(3) من قانون الولايات المتحدة ومقرها في واشنطن العاصمة ومهمتها هزيمة الشبكات غير المشروعة التي تهدد السلام والأمن العالميين. تعمل منظمة C4ADS مدعومًة بعلوم البيانات المتطورة، وتطبيقات التكنولوجيا المبتكرة، والتحليل المبني على الأدلة، وذلك للتنسيق بين الاستجابات العالمية الفعالة ضد دوافع النزاع وعدم الاستقرار والجرائم البيئية وانتهاكات حقوق الإنسان في جميع أنحاء العالم.`,
  FOLLOWING_THE_ESCALATION_OF_VI: `وبعد تصاعد العنف في السودان قامت منظمة C4ADS بالتنسيق مع C4ADS بالمملكة المتحدة بالتنسيق مع مركز مرونة المعلومات (CIR) ومركز حقوق الإنسان في السودان (HRH) لإنشاء مورد تقارير مستدام لتوثيق الصور والفيديوهات الملتقطة على الأرض وهيكلتها والتحقق منها. كما أعدت C4ADS خريطة "سودان شاهد" لتوحيد قاعدة البيانات المباشرة هذه مع غيرها من خرائط وقواعد بيانات تتبع النزاعات الأخرى عبر الشبكة الأكبر لشركاء C4ADS.`,
  THE_MAP_ALSO_FEATURES_CONFLICT: `تحتوي الخريطة أيضًا على أحداث صراع مجاملة من (`,
  ACLED: `ACLED`,
  _: `).`,
  WE_ARE_GRATEFUL_TO_ACLED_FOR_P: ` `,
  PARTNER_ORGANIZATIONS: `المنظمات الشريكة`,
  THE_CENTRE_FOR_INFORMATION_RES: `مركز مرونة المعلومات (CIR) هو مؤسسة اجتماعية مستقلة غير ربحية مكرسة لفضح انتهاكات حقوق الإنسان وجرائم الحرب ومكافحة المعلومات المضللة.`,
  THE_SUDAN_HUMAN_RIGHTS_HUB_HR: `مركز حقوق الإنسان في السودان (HRH) هو مركز للتنسيق ولتوفير الموارد يعمل على تمكين مجموعات حقوق الإنسان الشعبية من أرشفة الوثائق واستخدام البيانات للمساءلة والمناصرة.`,
  SUDAN_SHAHID_CONCEPTUALLY_BUIL: `يعتمد مشروع "سودان شاهد" من الناحية النظرية على مشروع "مراقبة الاحتجاج في السودان" التابع لمركز حقوق الإنسان في السودان (HRH)  بالإضافة إلى التعاون السابق بين منظمة C4ADS و مركز مرونة المعلومات  (CIR) المتمثل في:`,
  EYES_ON_RUSSIA_MAP: `: خريطة عيون على روسيا `,
  AND: `و`,
  AFGHAN_WITNESS_MAP: `خريطة الشاهد الأفغاني`,
  _1: `.`,
  THIS_PLATFORM_IS_NAMED_SUDAN_S: `تم تسمية هذه المنصة باسم "سودان شاهد" تكريماً للشركاء الرائعين على أرض السودان وفي جميع أنحاء العالم، الذين قاموا بتصوير وتوثيق وتبادل توثيق أعمال العنف الأخيرة لأغراض الشهادة وزيادة وعي المجتمع الدولي. وإننا ممتنون للشركاء الذين دعموا جمع هذه المعلومات القيمة، وهم:`,
  ARTRIKA_DATABASE_IS_AN_ONLINE: `قاعدة بيانات أرتريكا: وهي مشروع لتخزين البيانات عبر الإنترنت للحفاظ على بيانات ومعلومات الأشخاص الذين هجرتهم الحكومة السودانية من وطنهم إلى معسكرات النازحين. تستخدم قاعدة البيانات البحث والتاريخ الشفهي كأداتين لجمع البيانات؛`,
  AND_MANY_OTHERS_WHO_HAVE_CHOSE: `والعديد من الشركاء الآخرين الذين اختاروا عدم الكشف عن هوياتهم.`,
  WE_ARE_EAGER_TO_WELCOME_IN_NEW: `نحن حريصون على الترحيب بشركاء جدد في سودان شاهد، ويمكننا توفير التدريب وبناء القدرات من أجل دعم المزيد من التوثيق لهذا الصراع في جميع أنحاء العالم. وعلى الشركاء المهتمين التواصل على البريد الإليكتروني: `,
  INFO_SUDANSHAHID_ORG: `info@sudanshahid.org.`,
  ABOUT_THE_DATA: `نبذة عن البيانات`,
  SUDAN_SHAHID_DRAWS_ON_A_UNIFIE: `يعتمد مشروع "سودان شاهد" على قاعدة بيانات موحدة لمقاطع الفيديو والصور وصور الأقمار الصناعية والوسائط الأخرى المتعلقة بنزاع 2023 في السودان والتي قام أعضاء فريق "سودان شاهد" بجمعها والتحقق منها منذ 15 أبريل 2023. وتعرض الخريطة بيانات من ثلاثة مصادر: `,
  CIR_OPEN_SOURCE_MEDIA_INCLUD: `	مركز مرونة المعلومات (CIR): تحقق مركز مرونة المعلومات (CIR) من الوسائط مفتوحة المصدر (بما في ذلك الصور ومقاطع الفيديو).`,
  C_ADS_PARTNER_NETWORK_OPEN_A: `	شبكة شركاء C4ADS: وسائل الإعلام مفتوحة المصدر ومغلقة المصدر (بما في ذلك الصور ومقاطع الفيديو)، والتي وفرها شركاء منظمة C4ADS الموجودون في السودان.`,
  SATELLITE_DATA_PROVIDED_OR_CA: `	الأقمار الاصطناعية: البيانات المقدمة أو الملتقطة بواسطة الأقمار الصناعية، والتي توضح أحداث الصراع وقد لا يتم توثيقها باستخدام مصادر إعلامية على الأرض، وهي متاحة على منصات مفتوحة المصدر لزيادة الوعي العام.`,
  YOU_CAN_SELECT_THE_DATA_TO_BE: `يمكنك اختيار البيانات التي سيتم عرضها على الخريطة بأربع طرق:`,
  SELECT_ONE_OR_MORE_SOURCES_OF: `	اختر مصدرًا واحدًا أو أكثر للبيانات`,
  SELECT_ONE_OR_MORE_ACTORS_ALLE: `	اختر ممثلًا واحدًا أو أكثر يُزعم أنه ارتكب هذا الحدث`,
  SELECT_START_AND_END_DATES_FOR: `	حدد تواريخ البدء والانتهاء للفترة الزمنية قيد البحث`,
  SEARCH_FOR_KEY_WORDS_SUCH_AS_P: `	ابحث عن الكلمات الرئيسية مثل أسماء الأماكن باستخدام مربع النص الحر`,
  THESE_SEARCH_FUNCTIONS_CAN_BE: `يمكن تطبيق وظائف البحث هذه معًا أو كل وظيفة على حده. وستجد ادناه وصفًا للفئات.`,
  THE_MAP_WILL_PRESENT_DATA_MATC: `ستقدم الخريطة بيانات مطابقة لمعايير البحث والتصفية. ولمواصلة تحليل البيانات:`,
  HOVER_YOUR_CURSOR_OVER_A_PIN_T: `	ضع مؤشر الماوس فوق رمز الدبوس لعرض وصف موجز لخصائص البيانات`,
  CLICK_ON_A_PIN_TO_DISPLAY_A_PR: `	انقر فوق الدبوس لعرض مربع معاينة للبيانات وخصائصها، بما في ذلك رابط إلى المصدر الأصلي للبيانات`,
  MOVE_THE_BRACKETS_ALONG_THE_TI: `	حرك الأقواس على طول الخط الزمني في أسفل الشاشة واضغط على زر "تشغيل" لعرض فاصل زمني لظهور الدبابيس خلال الفترة الزمنية محل البحث`,
  INCREASE_OR_DECREASE_THE_MAGNI: `	قم بزيادة أو تقليل تكبير الخريطة وابحث عن الأحداث الموجودة داخل إطار الخريطة فقط`,
  CATEGORY_HARM_TO_PERSONS: `الفئة – إلحاق الضرر بالأشخاص`,
  ARREST_DETENTION_OR_ABDUCTION: `	الاعتقال أو الاحتجاز أو الاختطاف`,
  CIVILIAN_INJURY: `	إصابة مدني`,
  CIVILIAN_DEATH: `	وفاة مدني`,
  HUMILIATION_OF_CIVILIANS: `	إذلال المدنيين`,
  FORCED_RECRUITMENT_OR_CONSCRIP: `	التجنيد القسري أو التجنيد الإجباري المدنيين`,
  CIVILIAN_PROPERTY_LOOTING_OR_O: `	نهب ممتلكات المدنيين أو احتلالها`,
  DISPLACEMENT_OF_CIVILIANS: `	نزوح المدنيين`,
  INTERROGATION_OR_TORTURE_OF_CI: `	استجواب أو تعذيب المدنيين`,
  MASS_GRAVES_AND_BURIAL_SITES: `	المقابر الجماعية ومواقع الدفن`,
  UNEXPLODED_ORDINANCE: `	الذخائر غير المنفجرة`,
  HARM_TO_COMBATANTS: `	الإضرار بالمقاتلين`,
  CATEGORY_FORMS_OF_VIOLENCE: `الفئة – أشكال العنف`,
  GUNFIRE: `	إطلاق نار`,
  BOMBING_OR_EXPLOSION: `	قصف أو انفجار`,
  FIRE: `	النار`,
  PHYSICAL_VIOLENCE: `	العنف الجسدي`,
  AIRSTRIKES: `	الغارات الجوية`,
  CATEGORY_MILITARY_ACTIVITIES: `الفئة – الأنشطة العسكرية`,
  INCITEMENT_AND_HATE_SPEECH: `	التحريض وخطاب الكراهية`,
  FIRING_POSITIONS: `	مواقع إطلاق النار`,
  ROADBLOCKS_OR_RESTRICTIONS_ON: `	حواجز الطرق أو القيود المفروضة على الحركة الجسدية`,
  LOCATIONS_AND_MOVEMENTS: `	المواقع والحركات`,
  FORMS_OF_EQUIPMENT_OR_MUNITION: `	أشكال المعدات أو الذخائر`,
  CAPTURE_OF_OPPOSING_COMBATANTS: `	القبض على المقاتلين المعارضين `,
  CATEGORY_DAMAGE_TO_PROPERTY: `الفئة – الأضرار التي لحقت بالممتلكات أو البنية التحتية`,
  CIVILIAN_PROPERTY_DAMAGE: `	الأضرار التي لحقت بالممتلكات المدنية`,
  MEDICAL_AND_HEALTH_FACILITIES: `	الأضرار التي لحقت بالمرافق الطبية والصحية`,
  WATER_INFRASTRUCTURE_DAMAGE: `	الأضرار التي لحقت بالبنية التحتية للمياه`,
  EDUCATIONAL_FACILITIES_DAMAGE: `	الأضرار التي لحقت بالمرافق التعليمية`,
  HUMANITARIAN_AID_RESOURCES_SEI: `	الاستيلاء على موارد المساعدات الإنسانية أو إتلافها`,
  COMMERCIAL_FACILITIES_DAMAGE: `	الأضرار التي لحقت بالمنشآت التجارية`,
  GOVERNMENT_BUILDINGS_DAMAGE: `	الأضرار التي لحقت بالمباني الحكومية`,
  MILITARY_FACILITIES_AND_ASSETS: `	الأضرار التي لحقت المنشآت والأصول العسكرية`,
  INFRASTRUCTURE_DAMAGE: `	الأضرار التي لحقت بالبنية التحتية`,
  OTHER: `	أخرى`,
  VISIBLE_PERPETRATORS: `الجناة المرئيون`,
  RSF: `	مراسلون بلا حدود`,
  SAF: `	القوات المسلحة السودانية`,
  ARMED_SELF_DEFENSE_GROUPS: `	المجموعات المسلحة للدفاع عن النفس `,
  OTHER_PERPETRATORS_CAN_BE_IDE: `	أخرى (يمكن تحديد الجناة)`,
  UNKNOWN_PERPETRATORS_CANNOT_B: `	غير معروف (لا يمكن تحديد الجناة)`,
  EXTERNAL_FORCES_NON_SUDANESE: `	القوى الخارجية (القوات غير السودانية)`,
  THE_MAP_AND_UNDERLYING_DATABAS: `	تحتوي الخريطة وقاعدة البيانات الأساسية فقط على المعلومات التي جمعتها شبكة سودان شاهد. قاعدة البيانات ليست شاملة لجميع الأحداث ولا جميع المعلومات عن تلك الأحداث.`,
  EACH_PIECE_OF_DATA_HAS_BEEN_MA: `	تم وضع علامة على كل جزء من البيانات بمستوى العنف للإشارة إلى مستوى المحتوى الرسومي؛ ولا يتم تضمين المحتوى الرسومي العالي للغاية في مربعات المعاينة.`,
  THE_SUDAN_SHAHID_TEAM_DOES_NOT: `	فريق سودان شاهد لا ينشر معلومات قد تعرض أي فرد للخطر. وفي بعض الأحيان قد نقوم بتأخير النشر لضمان سلامة المدنيين في السودان.`,
  THE_SUDAN_SHAHID_TEAM_ONLY_USE: `	يستخدم فريق "سودان شاهد" البيانات التي جمعها وراجعها شركائنا فقط؛ ونحن لسنا مسؤولين عن الأحداث التي تتم على وسائل التواصل الاجتماعي ولا نؤيد الادعاءات المتعلقة بها.`,
  CIR_AS_ONE_DATA_CONTRIBUTOR_T: `	باعتباره أحد المساهمين في بيانات في مشروع "سودان شاهد" يقوم مركز مرونة المعلومات (CIR) بتشغيل بيانات مفتوحة المصدر من خلال عملية تحقق صارمة تتضمن عدة مراحل من المراجعة. ومع ذلك فإذا كنت تعتقد أن أي من بيانات CIR غير دقيقة، فيرجى إرسال بريد إلكتروني إلى osint@info-res.org لتوضيح سبب اعتقادك بأن البيانات غير صحيحة وسيقوم CIR بمراجعة تلك البيانات.`,
  ENGLISH: `English`,
  ARABIC: `العربية`,
  _3: `/`,
  ABOUT1: `/about`,
  DD_MM_YYYY: `DD_MM_YYYY`,
  SELECT: `اختيار`,
  RECTANGLE: `مستطيل`,
  POLYGON: `مضلع`,
  HIDE: `إخفاء`,
  SHOW: `إظهار`,
  GENERATING_DOWNLOADABLE_PDF_DO: `إنشاء مستند PDF قابل للتحميل`,
  SEARCH_QUERY: `Search Query:`,
  THE_SUDAN_SHAHID_MAP: `خريطة سودان شاهد`,
  BY_C_ADS: `بمعرفة  C4ADS`,
  SUDAN_SHAHID_IS_A_GLOBAL_COLLA: `سودان شاهد هو جهد تعاوني عالمي لجمع ورصد وتحليل التوثيق البصري للعنف المتصاعد في جميع أنحاء السودان`,
  IN_RESPONSE_TO_AN_OUTBREAK_OF1: `ورداً على اندلاع أعمال العنف بين القوات المسلحة السودانية المتحاربة، قامت C4ADS بالتنسيق مع مركز مرونة المعلومات (CIR) ومقره المملكة المتحدة ومركز حقوق الإنسان السوداني (HRH) بإنشاء هيكل دعم لمراقبة الصراع. وأدى هذا الجهد إلى إنشاء آلية لإعداد التقارير ومركز بيانات مخصص لتلبية احتياجات الشبكات الشريكة الموجودة على الأرض لتوثيق الصراع. تم إنشاء موقع "سودان شاهد" ليشهد على العنف المستمر في السودان ويفتح الباب أمام المساءلة الدولية، وهو بمثابة قاعدة بيانات تفاعلية للصور ومقاطع الفيديو التي تم التحقق منها والتي تم جمعها من خلال هذه الموارد إلى جانب صور الأقمار الصناعية ووسائل الإعلام الأخرى التي تم جمعها بواسطة CIR و HRH و الشبكة الأكبر لشركاء C4ADS . تحتوي الخريطة أيضًا على أحداث صراع مجاملة من (`,
  MORE_INFORMATION_ABOUT_THE_PRO: `يتوفر المزيد من المعلومات عن المشروع وإرشادات عن كيفية استخدام الخريطة `,
  AT_THE_ABOUT_PAGE: `في صفحة نبذة`,
  GRAPHIC_CONTENT_LEVEL: `مستوى المحتوى الرسومي:`,
  DESCRIPTION: `وصف:`,
  ACTOR_DETAILS: `تفاصيل الممثل:`,
  HEATMAP: `heatmap`,
  GEOJSON: `geojson`,
  BASEMAP: `الخريطة الأساسية`,
  DEFAULT: `Default`,
  CLASSIC: `Classic`,
  EARTH: `Earth`,
  CATEGORIES: `فئات`,
  MAP: "الخريطة",
  ABOUT: "نبذة",
  ACTOR: "الممثل",
  START_DATE: "تاريخ البدء",
  END_DATE: "تاريخ الانتهاء",
  ONLY_EVENTS_IN_MAP_FRAME: "الأحداث في إطار الخريطة فقط",
  RESET: "إعادة ضبط",
  NO_EVENTS_FOUND: "لم يتم العثور على أي أحداث",
  EVENTS_LOADING: "جارٍ تحميل الأحداث",
  LOADING: "جاري تحميل",
  NO_EVENTS_FOUND_REFRESH_PLEASE:
    "لم يتم العثور على أي أحداث. إذا كنت تعتقد أن هذه النتيجة غير صحيحة فيرجى إعادة تحميل الصفحة",
  SEARCH_LOC_OR_KEYWORDS: "مواقع البحث أو الكلمات الرئيسية",
  TIMELINE: "الجدول الزمني",
  SUDAN_SHAHID_MAP: "خريطة سودان شاهد",
  EVENTS: "الأحداث",
  ABOUT_THE_PROJECT_HEADER: "نبذة عن البيانات",
  HOW_TO_USE_MAP_HEADER: "كيف تستخدم الخريطة ",
  DEFINITIONS_HEADER: "تعريفات",
  WARNINGS_HEADER: "التحذيرات وإخلاء المسؤولية",
  SELECT_ALL: "اختر الكل",
  REMOVE_ALL: "حذف الكل",
  APPLY: "يتقدم",
  DRAW_ON_MAP: "الرسم على الخريطة",
  INFO: "المعلومات",
  DOWNLOAD_PDF: "تحميل PDF",
  LEGEND: "مفتاح الخريطة",
  KEYWORD_SEARCH_FOR: "بحث بكلمة ",
  ENTRY: "تدوين",
  ENTRY_NUM: "رقم التدوين",
  STATE: "ولاية",
  CITY: "مدينة",
  DATA_SOURCE: "مصدر البيانات",
  COORDINATES: "الإحداثيات",
  LINK: "صلة",
  HIDDEN: "مختفي",
  WHY_SUDAN_SHAHID: "لماذا أطلقنا اسم (سودان شاهد) على المشروع؟",
  THIS_PLATFORM_IS_NAMED:
    'تم تسمية هذه المنصة باسم "سودان شاهد" تكريماً للشركاء الرائعين على أرض السودان وفي جميع أنحاء العالم، الذين قاموا بتصوير وتوثيق وتبادل توثيق أعمال العنف الأخيرة لأغراض الشهادة وزيادة وعي المجتمع الدولي.',
  WE_ARE_GRATEFUL:
    "وإننا ممتنون للشركاء الذين دعموا جمع هذه المعلومات القيمة، فبدون شجاعتهم لن يعلم العالم بالمدى الكامل للعنف الذي يؤثر على شعب السودان.",
  EXPLORE_THE_SUDAN_SHAHID_MAP: "استكشف خريطة سودان شاهد",
};
